import { lazy, ReactNode, Suspense } from 'react';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import AuthHeader from '@/components/common/AuthHeader';
import HeroStatic from '@/components/common/HeroStatic';

const HeroAnimation = lazy(() => import('@/components/common/HeroAnimation'));

export const LayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  background: theme.palette.gradient1,
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const MainSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  paddingLeft: '90px',
  maxHeight: '100vh',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '30px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    maxHeight: 'initial',
  },
}));

export const HeroContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const FormSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: '100px',
  marginRight: '70px',
  [theme.breakpoints.down(1380)]: {
    marginRight: '30px',
    marginLeft: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: '15px',
    marginLeft: '15px',
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'start',
  },
}));

export const FormSectionContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '730px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'initial',
    overflowY: 'initial',
    maxHeight: 'initial',
  },
}));

export const ScrollableBox = styled(Box)(({ theme }) => ({
  width: '100%',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    overflowY: 'initial',
  },
}));

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <LayoutContainer>
      {downMd && <AuthHeader />}
      <ContentContainer>
        <MainSection>
          {!downMd && (
            <HeroContainer>
              <Suspense fallback={<HeroStatic />}>
                <HeroAnimation />
              </Suspense>
            </HeroContainer>
          )}
          <ScrollableBox>
            <FormSectionContainer>
              <FormSection>
                <ContentWrapper>{children}</ContentWrapper>
              </FormSection>
            </FormSectionContainer>
          </ScrollableBox>
        </MainSection>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default AuthLayout;
