import { Box, styled, Typography } from '@mui/material';
import BigLogo from '@/assets/icons/big_logo.svg?react';

export const StyledImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  background: theme.palette.gradient11,
  padding: '0 20px',
  alignItems: 'end',
  maxWidth: '620px',
  maxHeight: '970px',
  minWidth: '570px',
  borderBottomLeftRadius: '130px',
  [theme.breakpoints.down(1100)]: {
    minWidth: '400px',
  },
}));

export const TextContent = styled(Box)(({ theme }) => ({
  maxWidth: '400px',
  marginLeft: '50px',
  marginBottom: '150px',
  position: 'relative',
  [theme.breakpoints.down(1100)]: {
    marginLeft: '30px',
  },
}));

export const StyledLogo = styled(BigLogo)(({ theme }) => ({
  [theme.breakpoints.down(1100)]: {
    width: '130px',
  },
}));

export const LogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translate(34%, -85%)',
  [theme.breakpoints.down(1100)]: {
    transform: 'translate(-85%, -85%)',
  },
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: '60px',
  fontFamily: 'Inter',
  fontWeight: 700,
  color: '#fff',
  lineHeight: '70px',
  [theme.breakpoints.down(1100)]: {
    fontSize: '45px',
    lineHeight: '45px',
  },
}));

const HeroStatic = () => {
  return (
    <StyledImageBox>
      <TextContent>
        <StyledHeading>Talenver, where talent meets opportunity.</StyledHeading>
        <LogoWrapper>
          <StyledLogo />
        </LogoWrapper>
      </TextContent>
    </StyledImageBox>
  );
};

export default HeroStatic;
